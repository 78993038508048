import * as React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import { StaticImage } from "gatsby-plugin-image"

const NaitCompliance = () => (
  <Layout>
    <SEO
      title="NAIT Compliance"
      description="Knickers in a twist about having your cattle NAIT compliant? Time to give the team at Lakeside Lifestyle a call."
    />
    <section>
      <div className="container">
        <h1 className="section-heading">NAIT Compliance</h1>
        <div className="row justify-content-between">
          <div className="col-lg-5 d-flex align-items-center">
            <p>
              The NAIT system can be confusing, especially when you have a small
              number of animals. We can advise you on what you need to do to
              meet your legal obligations, help with the paperwork and we can
              even help you with tagging your animals.
            </p>
          </div>
          <div className="col-lg-6 mt-4 mt-lg-0 mb-4">
            <StaticImage
              aspectRatio={16 / 10}
              src="../../images/brown-cow.jpg"
              alt="cow"
              loading="eager"
            />
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default NaitCompliance
